import { apiClient } from "./ApiClient"


export function retrieveAllTodosForUsername(username){
    return apiClient.get(`/users/${username}/todos`)
}
export const retrieveAllTodosForUsernamePaginated = (params) => {
    return apiClient.get(`/users/${params.username}/todos`, {params})
};

export function deleteTodoApi(username, id){
    return apiClient.delete(`/users/${username}/todos/${id}`)
}

export function retrieveTodoApi(username, id){
    return apiClient.get(`/users/${username}/todos/${id}`)
}

export function updateTodoApi(username, id, todo){    
    return apiClient.post(`/users/${username}/todos/${id}`, todo)
}

export function createTodoApi(username, todo){
    return apiClient.post(`/users/${username}/todos`, todo)
}

//Admin///////////////////////////////////////////////////////////////
export function retrieveAllUsers(){
    return apiClient.get(`/admin/users`)
}
export function retrieveUserApi(id){
    return apiClient.get(`/admin/users/${id}`)
}
export function updateUserApi(username, id, user){
    return apiClient.post(`/admin/users/${id}`, user)
}
export function retrieveExistingRolesApi(){
    return apiClient.get(`/admin/roles`)
}

//////////// PAGINATION ///////////////////////////////////

export const getAll = (params) => {
    return apiClient.get("/pageone2/Guest", { params });
  };  