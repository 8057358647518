import { Link} from "react-router-dom"
import { useAuth } from "./security/AuthContext"
import { executeJwtAuthenticationServiceVersion } from "./api/AuthenticationApiService";
import { executeJwtAuthenticationServiceForbiddenUrl } from "./api/AuthenticationApiService";

function CheckEmailConfirmationComponent(){

    //const {username} = useParams()

    const authContext = useAuth()

    const username = authContext.username     
    
    async function handleSubmitVersion(){
        
        //const resultadoAwait = await authContext.login(username, password)

        try{
            const response = await executeJwtAuthenticationServiceVersion()

            alert("version data: "+response.data+" status: "+response.status);

        }catch(error){  			
			alert("version catch error: " + error.response.data)            
        }
        

        /*alert("after resultadoAwait Login: ");

        if(resultadoAwait) {
            //navigate(`/welcome/${username}`)                        
            navigate(`/welcome`)                        
        }
        else {
            setShowErrorMessage(true)
        }*/
    }
    async function handleSubmitForbiddenUrl(){
        try{
            const response = await executeJwtAuthenticationServiceForbiddenUrl()

            alert("forbidden data: "+response.data+" status: "+response.status);

        }catch(error){  			
			alert("forbidden catch error: " + error.response.data)            
        }
    }

    return (                
        
        <div className="container mt-5 box d-flex flex-column justify-content-center align-items-center">            
            <div>Check your email to confirm your registration. <Link to="/">Todos Home</Link></div>            
        </div>
    )
}

export default CheckEmailConfirmationComponent