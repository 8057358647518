import { useEffect, useState } from "react";
import { useAuth } from "./security/AuthContext"
import { useParams} from "react-router-dom";
import { executeJwtAuthenticationConfirmAccount } from "./api/AuthenticationApiService";

function SuggestionsComponent(){    

    const[confirmationMessage, setConfirmationMessage] = useState('')

    const authContext = useAuth()

    const[showErrorMessage, setShowErrorMessage] = useState(false)
    const[messageError, setMessageError] = useState('')

    //const {token} = useParams()    

    useEffect(() => {
        //confirm(token);                     
      }, []) 

    /*async function confirm(token){
        try{
            const response = await executeJwtAuthenticationConfirmAccount(token)
            if(response.status===200) {
                setConfirmationMessage(response.data)
            }
        }
        catch(error){
            setConfirmationMessage(error.response.data)
        }
    } */
    
    const[username, setUsername] = useState('')    
    
    function handleUsernameChange(event){   //alert('handleUsernameChange: '+event.target.value);
        setUsername(event.target.value)
    }

    async function handleSubmit(e){  //alert('submitting: '+username); 

        e.preventDefault()
        
        let errorMessage = {
            message: ''
        };
       
        const sugestao = {
            msg: username
        }         

        const resultadoAwait = await authContext.suggest(/*username*/sugestao, errorMessage/*confirmationMessage*/)

        if(resultadoAwait) {
            //navigate(`/welcome/${username}`)                        
            //navigate(`/welcome`)                        
            //alert("Success on Submit.");
            setShowErrorMessage(true)            
            setMessageError("Suggestion submitted.")

            //clear da textarea
            //alert("username: "+username);
            //this.postContent.value = "";
            //this.form.username = "";
            setUsername("");
        }
        else {
            //alert("Error on Submit!!!");
            setShowErrorMessage(true)            
            setMessageError(errorMessage.message)            
        }
    }

    return (
        <div className="container mt-5 box d-flex flex-column justify-content-center align-items-center">            
            <form onSubmit={handleSubmit}>
            <h1>Post your suggestion, please!</h1>
            {showErrorMessage && <div className="errorMessage">{messageError}</div>}
            <div className="SuggestionForm">   
            <div>
            <textarea name="postContent" value={username} rows={4} cols={40} maxLength={1000} onChange={handleUsernameChange} required />
            </div>
            <button type="submit">Submit</button>
            </div>
            </form>
        </div>
    )
}

export default SuggestionsComponent