import axios from "axios"

//const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqb2FvIiwiaWF0IjoxNjk0MjEwMzAzLCJleHAiOjE2OTQ0MTc2NjN9.7-MogxvDRDCttlBIiF5XB7LciMxQpsRG4pNTKSXwWqA'

//axios.defaults.headers.common['Authorization'] = `Bearer ${token}` 

//axios.defaults.headers.common['Authorization'] = `Bearer ${token}` 
//axios.defaults.headers.common['Authorization'] = 'Basic ' + window.btoa("user"+":"+"pass")

//axios.defaults.headers.common['Authorization'] = `Bearer ${token2}`;

export const apiClient = axios.create(
    {
        //baseURL: 'http://localhost:8080/restful-web-services-0.0.1-SNAPSHOT'
        //baseURL: 'http://localhost:8080'
        baseURL: 'http://mytodoslist.com/todorestapi-0.0.1-SNAPSHOT'
    }
);

let token = ''
                                //falta logica de guest
export const JWTToken = {    
    changeToken (newToken){      
      token = newToken
      }
    }

// Add a request interceptor
apiClient.interceptors.request.use(
    (config) => {
      //const token = localStorage.getItem('token');
      if (token) {
        console.log('novo interceptors login jwtToken: ' + token)
        //console.log('myvar: ' + myvar)
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );


  /*apiClient.interceptors.request.use(
    (config) => {
        //console.log('intercepting and adding a token2')
        //alert('intercepting and adding a token on login')
        console.log('interceptors login jwtToken: ' + jwtToken)
        config.headers.Authorization = jwtToken
        return config
    }
)*/