import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { retrieveTodoApi, updateTodoApi, createTodoApi } from "./api/TodoApiService"
import { useAuth } from "./security/AuthContext"
import { Formik, Form, Field, ErrorMessage } from "formik"
import moment from "moment/moment"

export default function TodoComponent(){
    
    const {id} = useParams()

    const[description, setDescription] = useState('')
    const[targetDate, setTargetDate] = useState('')
    const[progress, setProgress] = useState('')

    const authContext = useAuth()
    const navigate = useNavigate()

    const username = authContext.username

    useEffect(() => {
        retrieveTodos()
      }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

    function retrieveTodos(){  
        if(id !== -1){     
            retrieveTodoApi(username, id)
            .then(response => {
                setDescription(response.data.description)
                setTargetDate(response.data.targetDate)
                setProgress(response.data.progress)
            })
        }
        else{
            setTargetDate(moment().format('YYYY-MM-DD'));
        }
    }

    function onSubmit(values){
        console.log(values)
        const todo = {
            id: id,
            username: username,
            description: values.description, 
            targetDate: values.targetDate,
            progress: values.progress,
            done: false
        }

        if(id === -1){
            createTodoApi(username, todo)
                .then(response => {                    
                    navigate('/todos')
                })
                .catch(error => console.log(error))
        }
        else{
            updateTodoApi(username, id, todo)
                .then(response => {                                        
                    navigate('/todos')
                })
                .catch(error => console.log(error))
        }        
    }

    function validate(values){
        let errors = {}

        if(values.description.length<5){
            errors.description = 'Enter at least 10 characters'
        }

        if(values.targetDate === null || values.targetDate === '' || !moment(values.targetDate).isValid()){
            errors.targetDate = 'Enter a target date'
        }

        if(moment(values.targetDate).isBefore(moment())){            
            errors.targetDate = 'Target date should be in the future'
        }

        console.log(values)
        return errors
    }

    return (                      
        <div className="container" class="container mt-5 box d-flex flex-column justify-content-center align-items-center">
            <h1>Enter Todo Details</h1>
            <div>
                <Formik initialValues={ { description, targetDate, progress } }
                    enableReinitialize = {true}
                    onSubmit={onSubmit}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                {
                    (props) => (
                        <Form>
                            <ErrorMessage 
                                name="description"
                                component="div"
                                className="alert alert-warning"
                            />
                            <ErrorMessage 
                                name="targetDate"
                                component="div"
                                className="alert alert-warning"
                            />
                            <ErrorMessage 
                                name="progress"
                                component="div"
                                className="alert alert-warning"
                            />
                            <fieldset className="mb-3">                                
                                    <label>Description</label>
                                    <Field type="text" name="description" />                                
                            </fieldset>
                            <fieldset className="mb-3">
                                <label>Target Date</label>
                                <Field type="date" name="targetDate" />
                            </fieldset>

                            <fieldset className="mb-3">
                                <label>Progress</label>
                                <Field type="number" name="progress" />
                            </fieldset>

                            <div>
                                <button className="btn btn-success m-5" type="submit">Save</button>
                            </div>
                        </Form>
                    )
                }
                </Formik>
            </div>
        </div>
    )
}