import { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import { executeJwtAuthenticationConfirmAccount } from "./api/AuthenticationApiService";

function ConfirmAccountComponent(){    

    const[confirmationMessage, setConfirmationMessage] = useState('')

    const {token} = useParams()    

    useEffect(() => {
        confirm(token);                     
      }, []) 

    async function confirm(token){
        try{
            const response = await executeJwtAuthenticationConfirmAccount(token)
            if(response.status===200) {
                setConfirmationMessage(response.data)
            }
        }
        catch(error){
            setConfirmationMessage(error.response.data)
        }
    }        

    return (
        <div>{confirmationMessage}</div>
    )
}

export default ConfirmAccountComponent