import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "./security/AuthContext"
import { Link} from "react-router-dom"

function LoginComponent(){    

    const[email, setEmail] = useState('joao.fvieira.sousa2@gmail.com'/*'mod'*/)

    const[password, setPassword] = useState('m23456'/*'12345678'*/)

    const[showErrorMessage, setShowErrorMessage] = useState(false)
    const[messageError, setMessageError] = useState('')

    const navigate = useNavigate()

    const authContext = useAuth()

    function handleEmailChange(event){
        setEmail(event.target.value)
    }

    function handlePasswordChange(event){
        setPassword(event.target.value)
    }

    async function handleSubmit(){

        let errorMessage = {
            message: ''
        };
        
        const resultadoAwait = await authContext.login(email, password, errorMessage)

        if(resultadoAwait) {
            navigate(`/welcome`)
        }
        else {
            setShowErrorMessage(true)
            setMessageError(errorMessage.message)
        }
    }

    return (
        <div className="Login" class="container mt-5 box d-flex flex-column justify-content-center align-items-center">                      
            {showErrorMessage && <div className="errorMessage">{messageError}</div>}
            <div className="LoginForm">
                <div>
                    <label>Email</label>
                    <input type="text" name="email" value={email} onChange={handleEmailChange} />
                </div>                
                <div>
                    <label>Password</label>
                    <input type="password" name="password" value={password} onChange={handlePasswordChange}/>
                </div>
                <button type="button" name="login" onClick={handleSubmit}>Login</button>
            </div>
            <a href="/register">RegisterAhref</a>
            <Link className="nav-link" to="/register">RegisterLink</Link>
        </div>
    )
}

export default LoginComponent