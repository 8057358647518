import { BrowserRouter, Routes, Route } from "react-router-dom"
import LogoutComponent from './LogoutComponent'
//import FooterComponent from './FooterComponent'
import HeaderComponent from './HeaderComponent'
import ListTodosComponent from './ListTodosComponent'
//import ErrorComponent from './ErrorComponent'
import WelcomeComponent from './WelcomeComponent'
import LoginComponent from './LoginComponent'
import TodoComponent from "./TodoComponent"
import AdminComponent from "./AdminComponent"
import UsersComponent from "./UsersComponent"
import UserComponent from "./UserComponent"
import RegisterComponent from "./RegisterComponent"
import ConfirmAccountComponent from "./ConfirmAccountComponent"
import SuggestionsComponent from "./SuggestionsComponent"
import CheckEmailConfirmationComponent from "./CheckEmailConfirmationComponent"
import AuthProvider/*, { useAuth }*/ from './security/AuthContext'

import './TodoApp.css'

function AuthenticatedRoute({children}){
    //const authContext = useAuth()

    //if(authContext.isAuthenticated)
        return children

    //return <Navigate to="/" />
}

export default function TodoApp() {
    
    return (        
        <div className="TodoApp">
            <AuthProvider>
                
                        <HeaderComponent />                
                        <Routes>
                            <Route path="/login" element={<LoginComponent />} />                             
                            <Route path="/" element={
                                <AuthenticatedRoute>
                                    <WelcomeComponent />
                                </AuthenticatedRoute>
                            } />                     
                            <Route path="/welcome/:username" element={ <WelcomeComponent /> } />
                            <Route path="/welcome" element={
                                <AuthenticatedRoute>
                                    <WelcomeComponent />
                                </AuthenticatedRoute>
                            } />
                            <Route path="/todos" element={
                                <AuthenticatedRoute>
                                    <ListTodosComponent />
                                </AuthenticatedRoute>
                            } />
                            <Route path="/todo/:id" element={                            
                                <TodoComponent />                                             
                            } />
                            <Route path="/logout" element={
                                <AuthenticatedRoute>
                                    <LogoutComponent />
                                </AuthenticatedRoute>
                            } />
                            <Route path="/admin" element={
                                <AuthenticatedRoute>
                                    <AdminComponent />
                                </AuthenticatedRoute>
                            } />
                            <Route path="/admin/users" element={
                                <AuthenticatedRoute>
                                    <UsersComponent />
                                </AuthenticatedRoute>
                            } />
                            <Route path="/admin/user/:id" element={
                                <AuthenticatedRoute>
                                    <UserComponent />
                                </AuthenticatedRoute>
                            } />
                            <Route path="/register" element={                            
                                <RegisterComponent />                                             
                            } />
                            <Route path="/confirm-account" element={ <ConfirmAccountComponent />} />
                            <Route path="/confirm-account/:token" element={                            
                                <ConfirmAccountComponent />
                            } />
                            <Route path="/checkemailconfirmation" element={                            
                                <CheckEmailConfirmationComponent />
                            } />
                            <Route path="/suggestions" element={ <SuggestionsComponent />} />
                        </Routes>                
                
            </AuthProvider>
        </div>
        /*<div className="TodoApp">
            <AuthProvider>
                <BrowserRouter>
                    <HeaderComponent />
                    <Routes> 
                        <Route path="/" element={<LoginComponent />} />
                        <Route path="/login" element={<LoginComponent />} />                    
                        <Route path="/welcome/:username" element={
                            <AuthenticatedRoute>
                                <WelcomeComponent />
                            </AuthenticatedRoute>
                        } />
                        <Route path="/todos" element={
                            <AuthenticatedRoute>
                                <ListTodosComponent />
                            </AuthenticatedRoute>
                        } />
                        <Route path="/todo/:id" element={                            
                            <TodoComponent />                                             
                        } />
                        <Route path="/logout" element={
                            <AuthenticatedRoute>
                                <LogoutComponent />
                            </AuthenticatedRoute>
                        } />
                        <Route path="*" element={<ErrorComponent />} />
                    </Routes> 
                    <FooterComponent />
                </BrowserRouter>   
            </AuthProvider>     
        </div>        */
    )
}