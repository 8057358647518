import { createContext, useContext, useState, useEffect, useLayoutEffect, useCallback } from "react";
import { executeJwtAuthenticationService, executeJwtAuthenticationServiceVersion, executeJwtAuthenticationGuest } from "../api/AuthenticationApiService";
import { executeJwtSignup, executeJwtSuggest, executeJwtLogout, executeAllowedUrl } from "../api/AuthenticationApiService";
import { useCookies } from 'react-cookie';
import { apiClient, JWTToken } from "../api/ApiClient";

//1: Create a Context
export const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

//2: Share the created context with other components
export default function AuthProvider({ children }) {

    //3: Put some state in the context    
    const [isAuthenticated, setAuthenticated] = useState(false)
    const [isAdmin, setAdmin] = useState(false)    

    const [username, setUsername] = useState(null)
    const [userid, setUserid] = useState(null)

    const [flag, setFlag] = useState(false)
    
    //useLayoutEffect
    useEffect(() => {
            loadAuth()        
      }, [flag]) // eslint-disable-line react-hooks/exhaustive-deps      

    async function loadAuth(){        

        if(isAuthenticated == false){
            try{

                const response = await executeJwtAuthenticationGuest("Guest", "guest0")

                if(response.status===200) {

                    const tk = response.data.split('|');

                    var isadmin = tk[1]+"";

                    const jwtToken = tk[0]
                    console.log('guest login jwtToken: ' + jwtToken)                    
                    setUsername("Guest")
                    setUserid(tk[3])
    //                setToken(jwtToken)
                                     
                    setCookie('todosnewusere', 'Guest'+';'+userid+';true;false', {path: '/'})                                                                                    

                    JWTToken.changeToken(jwtToken)

                    return true           
                }
                else {
                    return false
                }
            }catch(error){
                return false
            }
        }

        if(cookies["todosnewusere"] != null){
            
            var myUser = cookies["todosnewusere"];
            var myUserArray = myUser.split(';');
            var cookieUsername = myUserArray[0];
            var cookieUserid = myUserArray[1];
            var cookieAuthenticated = myUserArray[2] === "true" ? true : false;

            var cookieIsAdmin = myUserArray[3] === "true" ? true : false;                
                
            setUsername(cookieUsername)
            setUserid(cookieUserid)                
            setAuthenticated(cookieAuthenticated)
            setAdmin(cookieIsAdmin)
                
            const response = await executeJwtAuthenticationGuest("Guest", "guest0")            
        }
        else{            
            setUsername("Guest")
            
            const response = await executeJwtAuthenticationGuest("Guest", "guest0")
            
            setAuthenticated(false)
            setAdmin(false)
            setCookie('todosnewusere', 'Guest;0;false;false', {path: '/'})
        }
    }

    const [cookies, setCookie] = useCookies(['todosnewusere']);

    async function signup(todo_user, errorMessage){        
        
        try{            
            const response = await executeJwtSignup(todo_user)            

            if(response.status===200) {
                return true
            }
            else{
                errorMessage.message = response.data
                return false
            }
        }
        catch(error){
            errorMessage.message = error.response.data.message
            return false
        }        
    }
    
    async function allowedurl(){ 
        try{ 
            const urlall = await executeAllowedUrl()
            return urlall.data
        }
        catch(error){  	
            //alert("catch do allowedurl -> "/* + error.response.data*/);		            			
            //errorMessage.message = error.response.data            
            //logout()
            //return false
        }
    }
    async function logout(){

        try{
            const response = await executeJwtLogout()

            if(response.status===200) {
                const myInterceptor = apiClient.interceptors.request.use(function () {/*...*/});
                apiClient.interceptors.request.eject(myInterceptor);
            }
        }
        catch(error){
        }        

        setAuthenticated(false)
//        setToken(null)
        setUsername("Guest")        
        //setCookie('todosnewusere', /*loggedInUser.id*/'Guest;false;false', {path: '/'})
        setCookie('todosnewusere', 'Guest'+';'+userid+';true;false', {path: '/'}) 
        setAdmin(false)
        
        setFlag(!flag)
    } 

    async function version(){
        try{
            const response = await executeJwtAuthenticationServiceVersion()
            if(response.status===200) {
                return true
            }
            else{
                return false
            }
        }
        catch(error){
            return false
        }
    }
    
    async function login(email, password, errorMessage){
        try{
            const response = await executeJwtAuthenticationService(email, password)

            if(response.status===200) {

                const tk = response.data.split('|');

                var isadmin = tk[1]+"";

                const jwtToken = tk[0]
                console.log('login jwtToken: ' + jwtToken)
                setAuthenticated(true)
                setUsername(tk[2])
                setUserid(tk[3])
//                setToken(jwtToken)                

                if(isadmin.includes("Admin")){                    
                    setCookie('todosnewusere', username +';'+userid+';true;true', {path: '/'})
                    setAdmin(true);                    
                }
                else{                    
                    setCookie('todosnewusere', username +';'+userid+';true;false', {path: '/'})
                    setAdmin(false);                    
                }

                JWTToken.changeToken(jwtToken)

                return true           
            }
            else {
                logout()
                return false
            }
        }catch(error){
            errorMessage.message = error.response.data            
            logout()
            return false
        }
    }

    async function suggest(sug, errorMessage){
        
        try{            
            const response = await executeJwtSuggest(sug)

            if(response.status===200) {
                return true
            }
            else{
                errorMessage.message = response.data
                return false
            }            
        }
        catch(error){            
            errorMessage.message = error.response.data.message            
            return false
        }
    }

    return (
        <AuthContext.Provider value={ {isAuthenticated, isAdmin, login, version, logout, signup, suggest, allowedurl, loadAuth, setFlag, username, userid, flag/*, token*/} }>
            {children}
        </AuthContext.Provider>
    )
}