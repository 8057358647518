import './App.css';
//import LearningComponent from './components/learning-examples/LearningComponent';
//import Counter from './components/counter/Counter';
import TodoApp from './components/todo/TodoApp'
import { CookiesProvider } from 'react-cookie';

/*function App() {
  return (
    <div className="App">      
      <LearningComponent/>      
    </div>
  );
}*/

function App() {
  return (
    <div className="App">
    <CookiesProvider>
      <TodoApp />
    </CookiesProvider>
    </div>
  );
}








export default App;
