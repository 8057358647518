import { apiClient } from "./ApiClient";

export const executeJwtAuthenticationService
    = (email, password) =>         
        apiClient.post(`/authenticate/signin`,{email, password})

export const executeJwtAuthenticationGuest
    = (email, password) =>         
        apiClient.post(`/authenticate/signin`,{email, password})

export const executeJwtSignup
        = (todo_user) =>             
            apiClient.post(`/authenticate/signup`, todo_user)            

export const executeJwtAuthenticationConfirmAccount
            = (token) =>                 
                apiClient.get(`/authenticate/confirm-account?token=${token}`)

export const executeJwtLogout
            = () =>
              apiClient.get(`/authenticate/logout`);              

export const executeAllowedUrl
            = () =>
              apiClient.get(`/allowedurl`);

export const executeJwtSuggest
                = (suggga) =>                     
                    apiClient.post(`/authenticate/suggestion`, suggga)

export const executeJwtAuthenticationServiceVersion
            = () =>                 
                apiClient.get(`/version`)              

export const executeJwtAuthenticationServiceVersion2
                = () => 
                    apiClient.get(`/version2`)

export const executeJwtAuthenticationServiceForbiddenUrl
                = () =>                     
                    apiClient.get('/forbiddenurl')