import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { retrieveUserApi, updateUserApi, retrieveExistingRolesApi } from "./api/TodoApiService"
//import { useAuth } from "./security/AuthContext"
import { Formik, Form, Field } from "formik"

export default function UserComponent(){
    
    const {id} = useParams()

    const[username, setUsername] = useState('')
    const[firstName, setFirstName] = useState('')
    const[lastName, setLastName] = useState('')    
    const[roles, setRoles] = useState([])
    const[existingRoles, setExistingRoles] = useState([])

    const navigate = useNavigate()   

    useEffect(() => {
        retrieveUser()
      }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

    function retrieveUser(){
        if(id !== -1){
            retrieveUserApi(id)
            .then(response => {                
                setUsername(response.data.email)
                setFirstName(response.data.firstName)
                setLastName(response.data.lastName)
                setRoles(response.data.roles)                
                })
                
            retrieveExistingRolesApi()
            .then(responseExistingRoles => {
                setExistingRoles(responseExistingRoles.data)
            })
        }        
    }

    function onSubmit(values){
        const user = {
            id: id,
            email: values.username,
            firstName: values.firstName, 
            lastName: values.lastName,
            roles: values.roles
        }        
        
        updateUserApi(username, id, user)
            .then(response => {                                        
                navigate('/admin/users')
            })
            .catch(error => console.log(error))
    }    
    
    function doCheck(rl){
        const isFoundUserRoles = roles.some(element => {
            if (element.name === rl) {                
                return true;
            }            
            return false;
        });
        return isFoundUserRoles;            
    }    
    
    const handleCheckboxChange = (e) => {        
        if (e.target.checked) {
            if (!roles.includes(e.target.value)) {
              const rl = {
                id: e.target.id,
                name: e.target.value
              }
              setRoles([...roles, rl]);
            }            
          } else {
            var filteredRoles = roles.filter(function (name, index, arr) {                
              return roles[index].name !== e.target.value;
            });
            setRoles(filteredRoles);
        }
    };

    return (                      
        <div className="container" class="container mt-5 box d-flex flex-column justify-content-center align-items-center">
            <h1>Edit User Details</h1>
            <div>
                <Formik initialValues={ { /*description, targetDate*/ username, firstName, lastName, roles } }
                    enableReinitialize = {true}
                    onSubmit={onSubmit}                    
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                {
                    (props) => (
                        <Form>                                                     
                            <fieldset className="mb-3">                                
                                    <label>Username</label>
                                    <Field type="text" name="username" disabled />                                
                            </fieldset>
                            <fieldset className="mb-3">
                                <label>First Name</label>
                                <Field type="text" name="firstName" disabled />
                            </fieldset>
                            <fieldset className="mb-3">
                                <label>Last Name</label>
                                <Field type="text" name="lastName" disabled />
                            </fieldset>

                            <div>
                                <table class="table table-striped table-bordered">
                                    <thead class="thead-dark">
                                        <tr>                            
                                            <th>ID</th>
                                            <th>Role</th> 
                                            <th></th>                                       
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {                                
                                        existingRoles.map(
                                            er => (
                                                <tr key={er.id}>                                    
                                                    <td>{er.id}</td>
                                                    <td>{er.name}</td>     
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            id={er.id}
                                                            name="roles"
                                                            value={er.name} 
                                                            defaultChecked={doCheck(er.name)}                       
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        
                                                    </td>                                                                                                                   
                                                </tr>
                                            )
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>                            

                            <div>
                                <button className="btn btn-success m-5" type="submit">Update</button>                                
                            </div>
                        </Form>
                    )
                }
                </Formik>
            </div>            
        </div>
    )
}