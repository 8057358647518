import { useState } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import { useAuth } from "./security/AuthContext"
import { Form } from "react-bootstrap";

function RegisterComponent(){

    const authContext = useAuth()

    const[username, setUsername] = useState('joao'/*'mod'*/)

    const[password, setPassword] = useState('12345678'/*'12345678'*/) 

    const[showErrorMessage, setShowErrorMessage] = useState(false)
    const[messageError, setMessageError] = useState('')

    const navigate = useNavigate();
    
    function handleUsernameChange(event){
        setUsername(event.target.value)
    }

    function handlePasswordChange(event){
        setPassword(event.target.value)
    }
    
    async function handleSubmit(e){

        e.preventDefault()

        let errorMessage = {
            message: ''
        };
        
        const todo_user = {
            email: username,
            password: password
        }

        const resultadoAwait = await authContext.signup(todo_user, errorMessage)

        if(resultadoAwait) {
            console.log("Success on Submit.")
            navigate(`/checkemailconfirmation`)
        }
        else {
            setShowErrorMessage(true)
            setMessageError(errorMessage.message)
        }

    }

    return (                
        
        <div className="RegisterComponent" class="container mt-5 box d-flex flex-column justify-content-center align-items-center">
            <form onSubmit={handleSubmit}>
            <h1>User Registration - Sign Up</h1>            
            {showErrorMessage && <div className="errorMessage">{messageError}</div>}
            <div className="SignupForm">
                <div>
                    <label>User Name:</label>
                    <input type="email" name="username" value={username} onChange={handleUsernameChange} maxLength={45} required />
                </div>
                <div>
                    <label>Password:</label>
                    <input type="password" name="password" value={password} onChange={handlePasswordChange} minLength={6} maxLength={64} required />
                </div>
                <button>Submit</button>
            </div>
            </form>            
        </div>
    )
}

export default RegisterComponent