import {Link} from "react-router-dom"
import { useAuth } from "./security/AuthContext"

function HeaderComponent(){
    
    const authContext = useAuth()
    const isAuthenticated = authContext.isAuthenticated
    const isAdmin = authContext.isAdmin

    function logout(){
        authContext.logout()
    }

    function admin(){
        //alert("admin");
        //authContext.logout()
    }

    return (
        <header className="border-bottom border-light border-5 mb-5 p-2">
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg">
                        <a className="navbar-brand ms-2 fs-2 fw-bold text-black" href="/">Todos</a>
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav">
                                <li className="nav-item fs-5">
                                    {isAuthenticated 
                                        && <Link className="nav-link" to="/welcome/in28minutes">Home</Link>}
                                </li>
                                <li className="nav-item fs-5">
                                    {isAuthenticated
                                        && <Link className="nav-link" to="/todos">Todos</Link>}
                                </li>
                            </ul>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item fs-5">
                                {!isAuthenticated && 
                                    <Link className="nav-link" to="/login">Login</Link>}
                            </li>
                            <li className="nav-item fs-5">
                                {isAuthenticated &&
                                    /*<Link className="nav-link" to="/logout" onClick={logout}>Logout</Link>}*/
                                    <Link className="nav-link" to="/" onClick={logout}>Logout</Link>}
                            </li>

                            <li className="nav-item fs-5">
                                {isAdmin &&
                                    /*<Link className="nav-link" to="/logout" onClick={logout}>Logout</Link>}*/
                                    <Link className="nav-link" to="/admin" onClick={admin}>Admin</Link>}
                            </li>


                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default HeaderComponent