import { Link} from "react-router-dom"
import { useAuth } from "./security/AuthContext"
import { executeJwtAuthenticationServiceVersion } from "./api/AuthenticationApiService";
import { executeJwtAuthenticationServiceVersion2 } from "./api/AuthenticationApiService";
import { executeJwtAuthenticationServiceForbiddenUrl } from "./api/AuthenticationApiService";

function WelcomeComponent(){

    //const {username} = useParams()

    const authContext = useAuth()

    const username = authContext.username     
    
    async function handleSubmitVersion(){   //alert("handleSubmitVersion");
        
        //const resultadoAwait = await authContext.login(username, password)

        //try{
        const response = await authContext.version()

        if(response) {                                
            alert("version data: "+response.data+" status: "+response.status);                      
        }
        else {
            alert("version error");
        }

        //    alert("version data: "+response.data+" status: "+response.status);

        //}catch(error){  			
		//	alert("version catch error: " + error.response.data)            
        //}
        

        /*alert("after resultadoAwait Login: ");

        if(resultadoAwait) {
            //navigate(`/welcome/${username}`)                        
            navigate(`/welcome`)                        
        }
        else {
            setShowErrorMessage(true)
        }*/
    }
    async function handleSubmitVersion2(){   alert("handleSubmitVersion2");                

        try{
            const response = await executeJwtAuthenticationServiceVersion2()

            alert("version data: "+response.data+" status: "+response.status);

        }catch(error){  			
			alert("version catch error: " + error.response.data)            
        }                
    }
    async function handleSubmitForbiddenUrl(){
        try{
            const response = await executeJwtAuthenticationServiceForbiddenUrl()

            alert("forbidden data: "+response.data+" status: "+response.status);

        }catch(error){  			
			alert("forbidden catch error: " + error.response.data)            
        }
    }    
    async function handleAllowedUrl(){
        //alert("handleAllUrl");
        //try{
            const allurl = await authContext.allowedurl()
            alert("allurl: " + allurl);
        //}
        /*catch(error){  			
			alert("handleAllowedUrl catch error: " + error.response.data)            
        }*/
    }

    return (                
        
        <div className="container mt-5 box d-flex flex-column justify-content-center align-items-center">
            <h1>Welcome {username}</h1>
            <div>Manage your todos. <Link to="/todos">Go here</Link></div>            
            <div className="text-info">{/*message*/}</div>

            <button type="button" name="version" onClick={handleSubmitVersion}>version_v1</button>
            <button type="button" name="version2" onClick={handleSubmitVersion2}>version2</button>
            <button type="button" name="forbiddenurl" onClick={handleSubmitForbiddenUrl}>forbiddenurl</button>            
            <button type="button" name="allowedurl" onClick={handleAllowedUrl}>allowed url</button>
        </div>
    )
}

export default WelcomeComponent