import { useEffect, useState } from "react"
import { retrieveAllUsers } from "./api/TodoApiService"
import { useNavigate } from "react-router-dom"

function WelcomeComponent(){

    const navigate = useNavigate()

    const [users, setUsers] = useState([]) 
    
    useEffect(() => {
        refreshUsers()
      }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function refreshUsers(){
        retrieveAllUsers()
        .then(response => {            
            setUsers(response.data)
        })
    }

    function editUser(id){
        navigate(`/admin/user/${id}`)
    }

    return (                
        
        <div className="container">
            <h1>Users</h1>
            <div>
                <table class="table table-striped table-bordered">
                    <thead class="thead-dark">
                        <tr>                            
                            <th>User ID</th>
                            <th>E-mail</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Roles</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        users.map(
                            usr => (
                                <tr key={usr.id}>                                    
                                    <td>{usr.id}</td>
                                    <td>{usr.email}</td>                                    
                                    <td>{usr.firstName}</td>
                                    <td>{usr.lastName}</td>
                                    <td>
                                    {usr.roles.map(rol => (
                                        <div key={rol.id}>                                            
                                            {rol.name}
                                        </div>
                                    ))}
                                    </td>
                                    <td><button className="btn btn-warning" onClick={() => editUser(usr.id)}>Edit</button></td>                                    
                                </tr>
                            )
                        )
                    }
                        
                    </tbody>
                </table>           
            </div>
        </div>
    )
}

export default WelcomeComponent