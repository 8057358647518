import { Link} from "react-router-dom"
import { useAuth } from "./security/AuthContext"

function WelcomeComponent(){    

    const authContext = useAuth()

    const username = authContext.username             

    return (                
        
        <div className="AdminComponent" class="container mt-5 box d-flex flex-column justify-content-center align-items-center">
            <h1>Admin, {username}</h1>
            <Link className="nav-link" to="/admin/users">Users</Link>            
        </div>
    )
}

export default WelcomeComponent