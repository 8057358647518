import { useEffect, useState } from "react"
import { deleteTodoApi, retrieveAllTodosForUsernamePaginated } from "./api/TodoApiService"
import { useAuth } from "./security/AuthContext"
import { useNavigate } from "react-router-dom"
import { ProgressBar } from 'react-bootstrap';
import Pagination from './Pagination';
import {useCallbackRef} from 'use-callback-ref';
//import { redirect } from "react-router-dom";

function ListTodosComponent(){
    //meter aqui a table da lesson 209, e o todos.map

    const authContext = useAuth()

    const username = authContext.username
    const userid = authContext.userid

    const navigate = useNavigate()    

    const [todos, setTodos] = useState([])    

    const [message, setMessage] = useState(null)    

    const [count, setCount] = useState(0);
    
    const currentPage = useCallbackRef(1, () => refreshTodos());

    const pageSizes = [4, 8, 12];    
    const [pageSize, setPageSize] = useState(4);  

//    const [doRefresh, setDoRefresh] = useState(0);

    const getRequestParams = (usr, userid, page, pageSize) => {        
        let params = {};  
        
        if (usr) {      
            params["username"] = usr;
          }

        if (userid) {
            params["userid"] = userid;
        }
    
        if (page) {      
          params["page"] = page - 1;
        }
    
        if (pageSize) {
          params["size"] = pageSize;
        }
    
        return params;
      };

    useEffect(() => {
        refreshTodos()
      }, [currentPage.current, pageSize, authContext.username]) // eslint-disable-line react-hooks/exhaustive-deps

    function refreshTodos(){
        const params = getRequestParams(username, userid, currentPage.current, pageSize);
        retrieveAllTodosForUsernamePaginated(params)
        .then(response => {
            const { todosReceived, totalPages } = response.data;
            setTodos(todosReceived)
            setCount(totalPages);
        })
        .catch(() => {
            authContext.setFlag(!authContext.flag)

            navigate(`/welcome`)
        })
    }

    function deleteTodo(id){
        deleteTodoApi(username, id)
        .then(
            () => {
                setMessage(`Delete of todo with id = ${id} successful`)
                refreshTodos()
            }
        )
        .catch(error => console.log(error))
    }

    function updateTodo(id){
        navigate(`/todo/${id}`)
    }

    function addNewTodo(){
        navigate(`/todo/-1`)
    }
        
    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        currentPage.current = 1
    };

    return (
        <div className="container">
            <h1>Things you want to do, {username}</h1>
            <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                    {pageSizes.map((size) => (
                    <option key={size} value={size}>
                        {size}
                    </option>
                    ))}
                </select>          
            </div>
            {message && <div className="alert alert-warning">{message}</div>}
            <div>
                <table className="table">
                    <thead>
                        <tr>                            
                            <th>Description</th>
                            <th>Target Date</th>
                            <th>Is Done?</th>  
                            <th></th>                          
                            <th></th>                            
                        </tr>
                    </thead>
                    <tbody>
                    {
                        todos.map(
                            todo => (
                                <tr key={todo.id}>                                    
                                    <td>{todo.description}</td>
                                    <td>{todo.targetDate.toString()}</td>
                                    <td>{todo.done.toString()}</td>
                                    <td>                                        
                                        <div className="progressBar" style={{ width: 100 + 'px' }}>                                            
                                            <ProgressBar now={todo.progress} label={`${todo.progress}%`} />
                                        </div>                                                                           
                                    </td>
                                    <td><button className="btn btn-success" onClick={() => updateTodo(todo.id)}>Update</button></td>                                                           
                                    <td><button className="btn btn-warning" onClick={() => deleteTodo(todo.id)}>Delete</button></td>                                    
                                </tr>
                            )
                        )
                    }
                        
                    </tbody>
                </table>   
                <div className='container mt-5'>                                      
                    {count > 1 && <Pagination
                        nPages={count}
                        currentPage={currentPage}                
                    />}
                </div>        
            </div>
            
            <div className="btn btn-success m-5" onClick={addNewTodo}>Add New Todo</div>
        </div>
    )
}

export default ListTodosComponent